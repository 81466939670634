.the-boys-container {
    width: 100%;
}

.participant-table-name-container {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    float: left;
}

.participant-table-name-container:hover {
    text-decoration: underline;
    cursor: pointer;
}

.player-header-container {
    text-align: left;
}

.player-profile-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: none;
}