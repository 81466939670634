.header-wrapper {
    text-align: left;
}

.header {
    display: inline-block;
    font-size: 2.5em;
    padding: 0.1em;
    color: white;
    font-family: 'Pacifico', cursive;
}

.header-logo {
    width: 100%;
    height: 10em;
}

/* Desktop View */
@media only screen and (min-device-width : 800px) {
    .header-logo {
       width: 40%;
    }
}