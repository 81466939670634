.legend-title-container {
    padding: 5px;
    margin: 5px;
    border-bottom: 1px solid darkgray;
}

.legend-title {
    font-size: 16px;
    color: gray;
}

.legend-title-subtext {
    font-size: 10px;
    color: gray;
}

.badge-legend-container {
    display: inline-block;
    background-color: white;
    border: 1px solid black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border: 1px solid black;
    display: inline-block;
    text-align: left;
}

.badge-legend-title {
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
    background-color: #8CACFD;
    color: white;
}